import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import { ClientJS } from "clientjs";
import { getCanonicalPatientId } from "../utils/patientUtils";
import storageRepository from "./storageRepository";

const __getSessionsRef = (entityId, isOrgUser) => {
  const collectionName = isOrgUser ? "organizations" : "usersV2";
  return collection(db, `${collectionName}/${entityId}/sessions/`);
};
const __getPatientsRef = (entityId, isOrgUser) => {
  const collectionName = isOrgUser ? "organizations" : "usersV2";
  return collection(db, `${collectionName}/${entityId}/patients/`);
};
const __getImagesRef = (entityId, isOrgUser) => {
  const collectionName = isOrgUser ? "organizations" : "usersV2";
  return collection(db, `${collectionName}/${entityId}/images/`);
};
const __getVideosRef = (entityId, isOrgUser) => {
  const collectionName = isOrgUser ? "organizations" : "usersV2";
  return collection(db, `${collectionName}/${entityId}/videos/`);
};
const __getUserRef = (uid) => {
  return doc(db, "usersV2", uid);
};
const __getOrgRef = (orgId) => {
  return doc(db, "organizations", orgId);
};
const __getEntityRef = (entityId, isOrgUser) => {
  return isOrgUser ? __getOrgRef(entityId) : __getUserRef(entityId);
};

const __getUserClaimsRef = (uid) => {
  return doc(db, "userClaims", uid);
};

const __getDataFromQueryAsync = async (querySnapshot) => {
  const queryResultSnapshot = await getDocs(querySnapshot);
  return queryResultSnapshot.docs.map((doc) => doc.data());
};

async function executeBatchedQueries(
  sessionsRef,
  numSessions,
  ownerIds = [],
  additionalWhereConditions = []
) {
  if (!Array.isArray(ownerIds) || ownerIds.length === 0) return [];

  const batchSize = 30;
  const batchedQueries = [];

  if (Array.isArray(ownerIds) && ownerIds.length > 0) {
    for (let i = 0; i < ownerIds.length; i += batchSize) {
      const batch = ownerIds.slice(i, i + batchSize);

      const newQuery = query(
        sessionsRef,
        where("deleted", "==", false),
        where("owner", "in", batch), // Only add this condition if ownerIds exist
        ...additionalWhereConditions,
        orderBy("createdAt", "desc"),
        limit(numSessions)
      );

      batchedQueries.push(__getDataFromQueryAsync(newQuery));
    }
  } else {
    // If no ownerIds are provided, execute a query without the owner filter
    const newQuery = query(
      sessionsRef,
      where("deleted", "==", false),
      ...additionalWhereConditions,
      orderBy("createdAt", "desc"),
      limit(numSessions)
    );

    batchedQueries.push(__getDataFromQueryAsync(newQuery));
  }

  const batchedResults = await Promise.all(batchedQueries);
  return batchedResults.flat();
}

function filterSessionsByTags(sessions, filterTags) {
  return sessions.filter((session) =>
    filterTags.every((tag) => session.tags.includes(tag))
  );
}

async function querySessionsWithOwner(
  entityId,
  isOrgUser,
  numSessions,
  ownerIds,
  additionalConditions = []
) {
  const sessionsRef = __getSessionsRef(entityId, isOrgUser);
  return executeBatchedQueries(
    sessionsRef,
    numSessions,
    ownerIds,
    additionalConditions
  );
}

async function getPatientGlobalId(entityId, isOrgUser, patientId) {
  const patientQuery = await getDocs(
    queryPatientByCanonicalPatientId(entityId, isOrgUser, patientId)
  );

  if (patientQuery.docs.length === 0) {
    return [];
  }
  return patientQuery.docs[0].id;
}

/************** SESSIONS ******************/

export async function createSession(
  entityId,
  isOrgUser,
  sessionData,
  updateLastDeviceSyncNeeded = true
) {
  const sessionsRef = __getSessionsRef(entityId, isOrgUser);
  const sessionGlobalId = getNewGlobalId(entityId, 0);
  if (!sessionData.patientGlobalId) {
    throw new Error("Patient ID is required to create a session");
  }
  if (!sessionData.imageGlobalIds) {
    sessionData.imageGlobalIds = [];
  }
  if (!sessionData.videoGlobalIds) {
    sessionData.videoGlobalIds = [];
  }
  sessionData.tags = sessionData.tags ? sessionData.tags : [];

  await setDoc(doc(sessionsRef, sessionGlobalId), {
    ...sessionData,
    globalId: sessionGlobalId,
    createdAt: serverTimestamp(),
    modifiedAt: serverTimestamp(),
    deleted: false,
  });

  if (updateLastDeviceSyncNeeded)
    await updateLastDeviceSync(entityId, isOrgUser);
  return sessionGlobalId;
}

export async function updateSession(
  entityId,
  isOrgUser,
  sessionId,
  sessionData,
  updateLastDeviceSyncNeeded = true
) {
  const sessionRef = doc(__getSessionsRef(entityId, isOrgUser), sessionId);
  await updateDoc(
    sessionRef,
    {
      ...sessionData,
      modifiedAt: serverTimestamp(),
    },
    { merge: true }
  );
  if (updateLastDeviceSyncNeeded)
    await updateLastDeviceSync(entityId, isOrgUser);
}

export function querySessionsDataByQueryIdx(
  entityId,
  isOrgUser,
  queryIdx,
  numSessions,
  searchParams
) {
  let endDate =
    searchParams.dateRange && searchParams.dateRange[0].endDate
      ? new Date(searchParams.dateRange[0].endDate)
      : null;
  if (endDate) {
    endDate.setDate(endDate.getDate() + 1);
  }
  switch (queryIdx) {
    case 0:
      return queryUnfilteredSessions(entityId, isOrgUser, numSessions);
    case 1:
      return querySessionByPatientId(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.patientId
      );
    case 2:
      return querySessionsByDate(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.dateRange[0].startDate,
        endDate
      );
    case 3:
      return querySessionsByTag(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.tags
      );
    case 4:
      return querySessionByDateAndPatientId(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.patientId,
        searchParams.dateRange[0].startDate,
        endDate
      );
    case 5:
      return querySessionsByDateAndTag(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.dateRange[0].startDate,
        endDate,
        searchParams.tags
      );
    case 6:
      return querySessionsByPatientIdAndTag(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.patientId,
        searchParams.tags
      );
    case 7:
      return querySessionsByDateAndPatientIdAndTag(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.patientId,
        searchParams.dateRange[0].startDate,
        endDate,
        searchParams.tags
      );
    case 8:
      return querySessionsByOwner(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.owner
      );
    case 9:
      return querySessionByDateAndOwner(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.owner,
        searchParams.dateRange[0].startDate,
        searchParams.dateRange[0].endDate
      );

    case 10:
      return querySessionByOwnerAndPatientId(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.owner,
        searchParams.patientId
      );
    case 11:
      return querySessionByOwnerAndTag(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.owner,
        searchParams.tags
      );
    case 12:
      return querySessionByOwnerAndDateAndPatientId(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.owner,
        searchParams.dateRange[0].startDate,
        searchParams.dateRange[0].endDate,
        searchParams.patientId
      );
    case 13:
      return querySessionByOwnerAndTagAndPatientId(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.owner,
        searchParams.tags,
        searchParams.patientId
      );
    case 14:
      return querySessionByOwnerAndDateAndTag(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.owner,
        searchParams.dateRange[0].startDate,
        searchParams.dateRange[0].endDate,
        searchParams.tags
      );
    case 15:
      return querySessionByOwnerAndDateAndPatientIdAndTag(
        entityId,
        isOrgUser,
        numSessions,
        searchParams.owner,
        searchParams.dateRange[0].startDate,
        searchParams.dateRange[0].endDate,
        searchParams.patientId,
        searchParams.tags
      );
    default:
      return null;
  }
}

export function queryUnfilteredSessions(entityId, isOrgUser, numSessions) {
  const sessionRef = __getSessionsRef(entityId, isOrgUser);
  const querySnapshot = query(
    sessionRef,
    where("deleted", "==", false),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );
  if (querySnapshot === null) {
    return [];
  }
  // Return query data
  return __getDataFromQueryAsync(querySnapshot);
}

export function querySessionsByDate(
  entityId,
  isOrgUser,
  numSessions,
  startDate,
  endDate
) {
  const sessionsRef = __getSessionsRef(entityId, isOrgUser);
  const querySnapshot = query(
    sessionsRef,
    where("deleted", "==", false),
    where("createdAt", ">=", startDate),
    where("createdAt", "<=", endDate),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );

  if (querySnapshot === null) {
    return [];
  }
  // Return query data
  return __getDataFromQueryAsync(querySnapshot);
}

export async function querySessionsByTag(
  entityId,
  isOrgUser,
  numSessions,
  filterTags
) {
  const sessionsRef = __getSessionsRef(entityId, isOrgUser);

  // Initial query to fetch sessions using array-contains-any
  const initialQuery = query(
    sessionsRef,
    where("deleted", "==", false),
    where("tags", "array-contains-any", filterTags),
    orderBy("createdAt", "desc")
  );
  const initialData = await __getDataFromQueryAsync(initialQuery);
  const filteredSessions = filterSessionsByTags(initialData, filterTags);
  return filteredSessions.slice(0, numSessions);
}

export async function querySessionByPatientId(
  entityId,
  isOrgUser,
  numSessions,
  patientId
) {
  const patientGlobalId = await getPatientGlobalId(
    entityId,
    isOrgUser,
    patientId
  );
  if (!patientGlobalId) return null;

  const sessionRef = __getSessionsRef(entityId, isOrgUser);
  const querySnapshot = query(
    sessionRef,
    where("deleted", "==", false),
    where("patientGlobalId", "==", patientGlobalId),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );

  return __getDataFromQueryAsync(querySnapshot);
}

export function queryPatientByCanonicalPatientId(
  entityId,
  isOrgUser,
  patientId
) {
  const canonicalPatientId = getCanonicalPatientId(patientId);
  const patientRef = __getPatientsRef(entityId, isOrgUser);
  return query(
    patientRef,
    where("canonicalPatientId", "==", canonicalPatientId),
    orderBy("createdAt", "desc")
  );
}

export async function querySessionByDateAndPatientId(
  entityId,
  isOrgUser,
  numSessions,
  patientId,
  startDate,
  endDate
) {
  const patientGlobalId = await getPatientGlobalId(
    entityId,
    isOrgUser,
    patientId
  );
  if (!patientGlobalId) return null;

  const sessionsRef = __getSessionsRef(entityId, isOrgUser);

  const querySnapshot = query(
    sessionsRef,
    where("deleted", "==", false),
    where("patientGlobalId", "==", patientGlobalId),
    where("createdAt", ">=", startDate),
    where("createdAt", "<=", endDate),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );

  if (querySnapshot === null) {
    return [];
  }
  // Return query data
  return __getDataFromQueryAsync(querySnapshot);
}

export async function querySessionByDateAndOwner(
  entityId,
  isOrgUser,
  numSessions,
  ownerIds,
  startDate,
  endDate
) {
  const sessions = await querySessionsWithOwner(
    entityId,
    isOrgUser,
    numSessions,
    ownerIds,
    [where("createdAt", ">=", startDate), where("createdAt", "<=", endDate)]
  );

  return sessions;
}

export async function querySessionByOwnerAndPatientId(
  entityId,
  isOrgUser,
  numSessions,
  ownerIds,
  patientId
) {
  const patientGlobalId = await getPatientGlobalId(
    entityId,
    isOrgUser,
    patientId
  );
  if (!patientGlobalId) return null;

  const sessions = await querySessionsWithOwner(
    entityId,
    isOrgUser,
    numSessions,
    ownerIds,
    [where("patientGlobalId", "==", patientGlobalId)]
  );

  return sessions;
}

export async function querySessionsByOwner(
  entityId,
  isOrgUser,
  numSessions,
  ownerIds
) {
  return await querySessionsWithOwner(
    entityId,
    isOrgUser,
    numSessions,
    ownerIds
  );
}
/**
 * Since we cannot use array-contains-any with owner field, we need to get all sessions for the owner
 * and then filter them by tags afterwards we re-construct the query with the filtered session ids
 **/

export async function querySessionByOwnerAndTag(
  entityId,
  isOrgUser,
  numSessions,
  ownerIds,
  filterTags
) {
  const sessions = await querySessionsWithOwner(
    entityId,
    isOrgUser,
    numSessions,
    ownerIds,
    [where("tags", "array-contains-any", filterTags)]
  );
  const filteredSessions = filterSessionsByTags(sessions, filterTags);
  return filteredSessions.slice(0, numSessions);
}

export async function querySessionByOwnerAndDateAndPatientId(
  entityId,
  isOrgUser,
  numSessions,
  ownerIds,
  startDate,
  endDate,
  patientId
) {
  const patientGlobalId = await getPatientGlobalId(
    entityId,
    isOrgUser,
    patientId
  );
  if (!patientGlobalId) return null;

  const sessions = await querySessionsWithOwner(
    entityId,
    isOrgUser,
    numSessions,
    ownerIds,
    [
      where("createdAt", ">=", startDate),
      where("createdAt", "<=", endDate),
      where("patientGlobalId", "==", patientGlobalId),
    ]
  );

  return sessions;
}

export async function querySessionByOwnerAndTagAndPatientId(
  entityId,
  isOrgUser,
  numSessions,
  ownerIds,
  filterTags,
  patientId
) {
  const patientGlobalId = await getPatientGlobalId(
    entityId,
    isOrgUser,
    patientId
  );
  if (!patientGlobalId) return null;

  const sessions = await querySessionsWithOwner(
    entityId,
    isOrgUser,
    numSessions,
    ownerIds,
    [
      where("patientGlobalId", "==", patientGlobalId),
      where("tags", "array-contains-any", filterTags),
    ]
  );

  const filteredSessions = filterSessionsByTags(sessions, filterTags);
  return filteredSessions.slice(0, numSessions);
}

export async function querySessionByOwnerAndDateAndTag(
  entityId,
  isOrgUser,
  numSessions,
  ownerIds,
  startDate,
  endDate,
  filterTags
) {
  const sessions = await querySessionsWithOwner(
    entityId,
    isOrgUser,
    numSessions,
    ownerIds,
    [
      where("createdAt", ">=", startDate),
      where("createdAt", "<=", endDate),
      where("tags", "array-contains-any", filterTags),
    ]
  );

  const filteredSessions = filterSessionsByTags(sessions, filterTags);
  return filteredSessions.slice(0, numSessions);
}

export async function querySessionByOwnerAndDateAndPatientIdAndTag(
  entityId,
  isOrgUser,
  numSessions,
  ownerIds,
  startDate,
  endDate,
  patientId,
  filterTags
) {
  const patientGlobalId = await getPatientGlobalId(
    entityId,
    isOrgUser,
    patientId
  );
  if (!patientGlobalId) return null;

  const sessions = await querySessionsWithOwner(
    entityId,
    isOrgUser,
    numSessions,
    ownerIds,
    [
      where("createdAt", ">=", startDate),
      where("createdAt", "<=", endDate),
      where("patientGlobalId", "==", patientGlobalId),
      where("tags", "array-contains-any", filterTags),
    ]
  );

  const filteredSessions = filterSessionsByTags(sessions, filterTags);
  return filteredSessions.slice(0, numSessions);
}

export async function querySessionsByDateAndTag(
  entityId,
  isOrgUser,
  numSessions,
  startDate,
  endDate,
  filterTags
) {
  const sessionsRef = __getSessionsRef(entityId, isOrgUser);

  // Initial query to fetch sessions within the date range and matching tags
  const initialQuery = query(
    sessionsRef,
    where("deleted", "==", false),
    where("createdAt", ">=", startDate),
    where("createdAt", "<=", endDate),
    where("tags", "array-contains-any", filterTags),
    orderBy("createdAt", "desc")
  );

  const initialData = await __getDataFromQueryAsync(initialQuery);

  const filteredSessions = filterSessionsByTags(initialData, filterTags);
  return filteredSessions.slice(0, numSessions);
}

export async function querySessionsByPatientIdAndTag(
  entityId,
  isOrgUser,
  numSessions,
  patientId,
  filterTags
) {
  // Validate filterTags
  if (!Array.isArray(filterTags) || filterTags.length === 0) return [];

  // Retrieve the patient global ID
  const patientGlobalId = await getPatientGlobalId(
    entityId,
    isOrgUser,
    patientId
  );
  if (!patientGlobalId) return []; // Return an empty array if patient is not found

  const sessionsRef = __getSessionsRef(entityId, isOrgUser);

  // Initial query to fetch sessions by patient global ID and matching tags
  const initialQuery = query(
    sessionsRef,
    where("deleted", "==", false),
    where("patientGlobalId", "==", patientGlobalId),
    where("tags", "array-contains-any", filterTags),
    orderBy("createdAt", "desc")
  );

  const initialData = await __getDataFromQueryAsync(initialQuery);

  const filteredSessions = filterSessionsByTags(initialData, filterTags);
  return filteredSessions.slice(0, numSessions);
}

export async function querySessionsByDateAndPatientIdAndTag(
  entityId,
  isOrgUser,
  numSessions,
  patientId,
  startDate,
  endDate,
  filterTags
) {
  // Retrieve the patient global ID
  const patientGlobalId = await getPatientGlobalId(
    entityId,
    isOrgUser,
    patientId
  );
  if (!patientGlobalId) return []; // Return an empty array if patient is not found

  const sessionsRef = __getSessionsRef(entityId, isOrgUser);

  // Initial query to fetch sessions by patient global ID, date range, and matching tags
  const initialQuery = query(
    sessionsRef,
    where("deleted", "==", false),
    where("patientGlobalId", "==", patientGlobalId),
    where("createdAt", ">=", startDate),
    where("createdAt", "<=", endDate),
    where("tags", "array-contains-any", filterTags),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );

  const initialData = await __getDataFromQueryAsync(initialQuery);

  // Filter sessions to ensure they match all filterTags
  const allTagSessions = initialData.filter((session) =>
    filterTags.every((tag) => session.tags.includes(tag))
  );

  // Return filtered sessions, limited to numSessions
  return allTagSessions.slice(0, numSessions);
}

export async function getSessionById(entityId, isOrgUser, sessionId) {
  const sessionDocRef = doc(__getSessionsRef(entityId, isOrgUser), sessionId);
  const sessionDoc = await getDoc(sessionDocRef);
  return sessionDoc.data();
}

export async function deleteSession(
  entityId,
  isOrgUser,
  sessionId,
  updateLastDeviceSyncNeeded = true
) {
  const sessionDocRef = doc(__getSessionsRef(entityId, isOrgUser), sessionId);
  await updateDoc(
    sessionDocRef,
    {
      deleted: true,
      modifiedAt: serverTimestamp(),
    },
    { merge: true }
  );
  // Delete all images and videos associated with the session
  const sessionDoc = await getDoc(sessionDocRef);
  const sessionData = sessionDoc.data();
  const deletePromises = [];

  if (sessionData.imageGlobalIds) {
    const imageDeletePromises = sessionData.imageGlobalIds.map((imageId) =>
      deleteImage(entityId, isOrgUser, imageId, false)
    );
    deletePromises.push(...imageDeletePromises);
  }

  if (sessionData.videoGlobalIds) {
    const videoDeletePromises = sessionData.videoGlobalIds.map((videoId) =>
      deleteVideo(entityId, isOrgUser, videoId, false)
    );
    deletePromises.push(...videoDeletePromises);
  }

  try {
    await Promise.all(deletePromises);
  } catch (error) {
    console.error("An error occurred while deleting:", error);
  }

  if (updateLastDeviceSyncNeeded) {
    await updateLastDeviceSync(entityId, isOrgUser);
  }
}

/************** IMAGES ******************/
export async function getImagesBySession(entityId, isOrgUser, session) {
  const imagesRef = __getImagesRef(entityId, isOrgUser);
  let images = [];
  await Promise.all(
    session.imageGlobalIds.map(async (imageId) => {
      const imageDocRef = doc(imagesRef, imageId);
      const imageDoc = await getDoc(imageDocRef);
      if (imageDoc.exists()) {
        let imageData = imageDoc.data();
        if (imageData && !imageData.deleted && imageData.isInCloudStorage) {
          try {
            if (
              !imageData.downloadURL ||
              !imageData.thumbnailURL ||
              !imageData.thumbnailSessionURL
            ) {
              imageData = await refetchDownloadUrls(
                entityId,
                isOrgUser,
                imageData
              );
            }
            if (imageData) images.push(imageData);
          } catch (error) {
            console.error("Error getting download URLs for image", error);
          }
        }
      }
    })
  );
  return images;
}

export async function countImagesInStorage(entityId, isOrgUser) {
  const imagesRef = __getImagesRef(entityId, isOrgUser);
  const imagesSnapshot = await getDocs(imagesRef);
  return imagesSnapshot.docs.filter(
    (doc) => doc.data().isInCloudStorage && !doc.data().deleted
  ).length;
}

export async function createImage(
  entityId,
  isOrgUser,
  fileExtension,
  isInCloudStorage,
  updateLastDeviceSyncNeeded = true
) {
  const imagesRef = __getImagesRef(entityId, isOrgUser);
  const imageGlobalId = getNewGlobalId(entityId, 1);
  const imageDocRef = doc(imagesRef, imageGlobalId);
  const imageData = {
    url: `${entityId}/images/${imageGlobalId}.${fileExtension}`,
    globalId: imageGlobalId,
    isInCloudStorage: isInCloudStorage,
    createdAt: serverTimestamp(),
    modifiedAt: serverTimestamp(),
    osOdFeature: "none",
    keepMode: 0,
    deleted: false,
  };
  await setDoc(imageDocRef, imageData);
  if (updateLastDeviceSyncNeeded)
    await updateLastDeviceSync(entityId, isOrgUser);
  return imageData;
}

export async function updateImage(
  entityId,
  isOrgUser,
  imageId,
  updatedImageData,
  updateLastDeviceSyncNeeded = true
) {
  const imagesRef = __getImagesRef(entityId, isOrgUser);
  const imageDocRef = doc(imagesRef, imageId);
  await updateDoc(
    imageDocRef,
    {
      ...updatedImageData,
      modifiedAt: serverTimestamp(),
    },
    { merge: true }
  );
  if (updateLastDeviceSyncNeeded)
    await updateLastDeviceSync(entityId, isOrgUser);
}

export async function deleteImage(
  entityId,
  isOrgUser,
  imageId,
  updateLastDeviceSyncNeeded = true
) {
  const imageDocRef = doc(__getImagesRef(entityId, isOrgUser), imageId);
  await updateDoc(
    imageDocRef,
    {
      deleted: true,
      modifiedAt: serverTimestamp(),
    },
    { merge: true }
  );
  if (updateLastDeviceSyncNeeded)
    await updateLastDeviceSync(entityId, isOrgUser);
}

/************** VIDEOS ******************/
export async function getVideosBySession(entityId, isOrgUser, session) {
  const videosRef = __getVideosRef(entityId, isOrgUser);
  let videos = [];
  await Promise.all(
    session.videoGlobalIds.map(async (videoId) => {
      const videoDocRef = doc(videosRef, videoId);
      const videoDoc = await getDoc(videoDocRef);
      if (videoDoc.exists()) {
        let videoData = videoDoc.data();
        if (videoData && !videoData.deleted && videoData.isInCloudStorage) {
          try {
            if (
              !videoData.downloadURL ||
              !videoData.thumbnailURL ||
              !videoData.thumbnailSessionURL
            ) {
              videoData = await refetchDownloadUrls(
                entityId,
                isOrgUser,
                videoData
              );
            }
            if (videoData) videos.push(videoData);
          } catch (error) {
            console.error("Error getting download URLs for video", error);
          }
        }
      }
    })
  );
  return videos;
}

export async function countVideosInStorage(entityId, isOrgUser) {
  const videosRef = __getVideosRef(entityId, isOrgUser);
  const videosSnapshot = await getDocs(videosRef);
  return videosSnapshot.docs.filter(
    (doc) => doc.data().isInCloudStorage && !doc.data().deleted
  ).length;
}

export async function createVideo(
  entityId,
  isOrgUser,
  fileExtension,
  isInCloudStorage,
  updateLastDeviceSyncNeeded = true
) {
  const videosRef = __getVideosRef(entityId, isOrgUser);
  const videoGlobalId = getNewGlobalId(entityId, 2);
  const videoDocRef = doc(videosRef, videoGlobalId);
  const videoData = {
    url: `${entityId}/videos/${videoGlobalId}.${fileExtension}`,
    globalId: videoGlobalId,
    isInCloudStorage: isInCloudStorage,
    createdAt: serverTimestamp(),
    modifiedAt: serverTimestamp(),
    osOdFeature: "none",
    keepMode: 0,
    deleted: false,
  };
  await setDoc(videoDocRef, videoData);
  if (updateLastDeviceSyncNeeded)
    await updateLastDeviceSync(entityId, isOrgUser);
  return videoData;
}

export async function updateVideo(
  entityId,
  isOrgUser,
  videoId,
  updatedVideoData,
  updateLastDeviceSyncNeeded = true
) {
  const videosRef = __getVideosRef(entityId, isOrgUser);
  const videoDocRef = doc(videosRef, videoId);
  await updateDoc(
    videoDocRef,
    {
      ...updatedVideoData,
      modifiedAt: serverTimestamp(),
    },

    { merge: true }
  );
  if (updateLastDeviceSyncNeeded)
    await updateLastDeviceSync(entityId, isOrgUser);
}

export async function deleteVideo(
  entityId,
  isOrgUser,
  videoId,
  updateLastDeviceSyncNeeded = true
) {
  const videoDocRef = doc(__getVideosRef(entityId, isOrgUser), videoId);
  await updateDoc(
    videoDocRef,
    {
      deleted: true,
      modifiedAt: serverTimestamp(),
    },
    { merge: true }
  );
  if (updateLastDeviceSyncNeeded)
    await updateLastDeviceSync(entityId, isOrgUser);
}

/************** PATIENTS ******************/
export async function getPatientBySession(entityId, isOrgUser, session) {
  const patientRef = __getPatientsRef(entityId, isOrgUser);
  const patientDocRef = doc(patientRef, session.patientGlobalId);
  const patientDoc = await getDoc(patientDocRef);
  return patientDoc.data();
}

export async function getAllPatients(entityId, isOrgUser) {
  const patientRef = __getPatientsRef(entityId, isOrgUser);
  const patientSnapshot = await getDocs(patientRef);
  const patientData = patientSnapshot.docs.map((doc) => doc.data());
  return patientData;
}

export async function createPatient(
  entityId,
  isOrgUser,
  patientName,
  updateLastDeviceSyncNeeded = true
) {
  const patientsRef = __getPatientsRef(entityId, isOrgUser);
  const patientGlobalId = getNewGlobalId(entityId, 3);
  const canonicalPatientId = getCanonicalPatientId(patientName);
  const patientData = {
    patientId: patientName,
    globalId: patientGlobalId,
    canonicalPatientId: canonicalPatientId,
    createdAt: serverTimestamp(),
    modifiedAt: serverTimestamp(),
    deleted: false,
  };
  // Check if there is a patient with the same canonicalPatientId
  const patientQuery = await getDocs(
    query(
      patientsRef,
      where("canonicalPatientId", "==", patientData.canonicalPatientId)
    )
  );
  if (patientQuery.docs.length > 0) {
    throw new Error("Patient with same canonicalId already exists");
  }
  await setDoc(doc(patientsRef, patientGlobalId), patientData);
  if (updateLastDeviceSyncNeeded)
    await updateLastDeviceSync(entityId, isOrgUser);
  return patientGlobalId;
}

export async function updatePatient(
  entityId,
  isOrgUser,
  patientId,
  updatedpatientName,
  updateLastDeviceSyncNeeded = true
) {
  const patientsRef = __getPatientsRef(entityId, isOrgUser);
  const patientDocRef = doc(patientsRef, patientId);
  const canonicalId = getCanonicalPatientId(updatedpatientName);
  // Check if there is a patient with the same canonicalPatientId and different globalId. Only one patient with the same canonicalPatientId is allowed
  const patientQuery = await getDocs(
    query(patientsRef, where("canonicalPatientId", "==", canonicalId))
  );
  if (patientQuery.docs.length > 0 && patientQuery.docs[0].id !== patientId) {
    throw new Error("Another patient with same canonicalId already exists");
  }
  const patientData = {
    patientId: updatedpatientName,
    canonicalPatientId: canonicalId,
    modifiedAt: serverTimestamp(),
  };
  await updateDoc(patientDocRef, patientData, { merge: true });
  if (updateLastDeviceSyncNeeded)
    await updateLastDeviceSync(entityId, isOrgUser);
}

/************** TAGS ******************/
export async function getAllTags(entityId, isOrgUser) {
  const sessionsRef = __getSessionsRef(entityId, isOrgUser);
  const sessions = await getDocs(sessionsRef);
  let tags = [];
  sessions.forEach((session) => {
    const sessionData = session.data();
    if (sessionData.tags) {
      tags = tags.concat(sessionData.tags);
    }
  });
  return [...new Set(tags.filter((tag) => tag !== ""))];
}

/************** USER ******************/
export function getUserReference(uid) {
  return __getUserRef(uid);
}

export function getUserDocument(uid) {
  const userRef = __getUserRef(uid);
  return getDoc(userRef);
}

export async function getUserData(uid) {
  const userRef = __getUserRef(uid);
  const userDoc = await getDoc(userRef);
  return userDoc.data();
}

export async function getUserClaims(uid) {
  const userClaimsRef = __getUserClaimsRef(uid);
  const userClaimsDoc = await getDoc(userClaimsRef);
  return userClaimsDoc.data();
}

export async function createUser(uid, userData) {
  // Assert mandatory fields are provided
  if (!uid) throw new Error("UID is required");
  if (!userData.email) throw new Error("Email is required");
  if (!userData.signinMethod) throw new Error("Sign-in method is required");

  const completeUserData = {
    country: "",
    created: serverTimestamp(),
    lastName: "",
    modified: serverTimestamp(),
    newsletter: false,
    phoneModel: "Web",
    speciality: "",
    stripeSubscriptionId: 0,
    stripeCustomerId: "",
    ...userData, // This ensures that the provided userData overrides the default values
  };
  const userRef = __getUserRef(uid); // Fixed to use the function parameter 'uid' instead of 'user.uid'
  await setDoc(userRef, completeUserData);
}

export async function updateUser(uid, userData) {
  const userRef = __getUserRef(uid);
  await updateDoc(userRef, {
    ...userData,
    modified: serverTimestamp(),
  });
}

export async function deleteUser(uid) {
  const userRef = __getUserRef(uid);
  await deleteDoc(userRef);
}

/************** ORGDATA ******************/
export async function getOrgData(orgId) {
  const orgRef = __getOrgRef(orgId);
  const orgDoc = await getDoc(orgRef);
  return orgDoc.data();
}

/************** DEVICE SYNC ******************/
export async function listenDeviceSyncChanges(
  entityId,
  isOrgUser,
  lastDeviceSyncRef,
  setLastDeviceSync,
  loadDataCallback, // Callback function to load data. This is passed from the component
  debounceTimerRef
) {
  const entityRef = __getEntityRef(entityId, isOrgUser);
  const unsubscribe = onSnapshot(entityRef, (doc) => {
    if (doc.exists) {
      const entityData = doc.data();
      const entitySyncData = entityData.lastDeviceSync;

      let hasChanged = false;

      // If a bad initialization causes lastDeviceSync to be undefined, we set it to the current userSyncData
      if (!lastDeviceSyncRef.current) {
        setLastDeviceSync(entitySyncData);
      } else {
        // Check if any key-value pair has changed
        hasChanged = Object.keys(entitySyncData).some((key) => {
          const entityTimestamp = entitySyncData[key]?.toDate().getTime();
          const lastTimestamp = lastDeviceSyncRef.current[key]
            ?.toDate()
            .getTime();
          return entityTimestamp !== lastTimestamp;
        });
      }

      if (hasChanged) {
        // Clear the existing debounce timer if it exists
        if (debounceTimerRef.current) {
          clearTimeout(debounceTimerRef.current);
        }

        // Set a new debounce timer
        debounceTimerRef.current = setTimeout(() => {
          setLastDeviceSync(entitySyncData);
          loadDataCallback(); // Invoke the callback function to load data in father component
        }, 3000); // Wait 3 seconds. Delay time defined heuristically to ensure thumbnails automatically display after upload
      }
    }
  });

  // Return the unsubscribe function to be called when needed
  return unsubscribe;
}

/************** ACCESSCONNECT ******************/
export async function setConnectAccessed(uid) {
  const updateLastAccessed = async () => {
    try {
      const userRef = __getUserRef(uid);
      const documentSnapshot = await getDoc(userRef);
      const data = documentSnapshot.data();
      if (data) {
        const {
          connectLastAccessed,
          connectFirstAccessed,
          connectAccessCount,
        } = data;
        if (
          (!connectFirstAccessed, !connectLastAccessed, !connectAccessCount)
        ) {
          await updateDoc(
            userRef,
            {
              connectLastAccessed: serverTimestamp(),
              connectAccessCount: 1,
              connectFirstAccessed: serverTimestamp(),
            },
            { merge: true }
          );
        }

        const lastAccessedPlusTwelveHours = new Date(
          connectLastAccessed.toDate()
        );
        lastAccessedPlusTwelveHours.setHours(
          lastAccessedPlusTwelveHours.getHours() + 12
        );

        if (lastAccessedPlusTwelveHours < new Date()) {
          await updateDoc(
            userRef,
            {
              connectLastAccessed: serverTimestamp(),
              connectAccessCount: data.connectAccessCount + 1,
            },
            { merge: true }
          );
        }
      }
    } catch (error) {
      console.error("Error updating last accessed timestamp:", error);
    }
  };

  return updateLastAccessed();
}

/************** LAST DEVICE SYNC ******************/
export async function updateLastDeviceSync(entityId, isOrgUser) {
  const entityRef = __getEntityRef(entityId, isOrgUser);
  // Fetch the current document
  const docSnap = await getDoc(entityRef);

  // If the document exists
  if (docSnap.exists()) {
    // Get the current data
    let data = docSnap.data();

    // If lastDeviceSync exists
    if (data.lastDeviceSync) {
      // Update the WEB timestamp
      data.lastDeviceSync.WEB = serverTimestamp();
    } else {
      // If lastDeviceSync does not exist, create it
      data.lastDeviceSync = {
        WEB: serverTimestamp(),
      };
    }
    // Update the document with the new data
    await updateDoc(entityRef, data, { merge: true });
  }
}

/************** HELPERS ******************/

/**
 * Generates a new global ID for a new asset.
 * @param {string} entityId - The entity ID.
 * @param {number} documentTypeIdx - The index of the document type.
 * @returns {string} - The new global ID.
 */
export function getNewGlobalId(entityId, documentTypeIdx) {
  let documentType;
  switch (documentTypeIdx) {
    case 0:
      documentType = "SESSION";
      break;
    case 1:
      documentType = "IMAGE";
      break;
    case 2:
      documentType = "VIDEO";
      break;
    case 3:
      documentType = "PATIENT";
      break;
    default:
      throw new Error("Invalid document type index");
  }
  const client = new ClientJS();
  const fingerprint = client.getFingerprint().toString();
  const slicedFingerpring = fingerprint.slice(0, 8);
  const first2 = entityId.slice(0, 5);
  const d = new Date();
  const datestring =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2) +
    "_" +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2) +
    ":" +
    ("0" + d.getSeconds()).slice(-2) +
    ":" +
    ("0" + d.getMilliseconds()).slice(-3);

  return (
    documentType +
    "_WEB_" +
    `${first2}` +
    "-" +
    slicedFingerpring +
    "_" +
    datestring
  );
}

/**
 * Refetches the download URLs for the given asset and updates the Firestore document.
 * @param {string} uid - The user ID.
 * @param {Object} assetData - The asset data.
 * @returns {Promise<Object>} - A promise that resolves to the updated asset data.
 */
export async function refetchDownloadUrls(entityId, isOrgUser, assetData) {
  // Get the global ID of the asset
  const assetGlobalId = assetData.globalId;
  // Get the file extension of the asset
  const fileExtension = assetData.url.split(".").pop();
  // Check if the asset is an image
  const isImage = assetGlobalId.includes("IMAGE");
  // Get the index of the document type
  const documentTypeIdx = isImage ? 1 : 2;
  // Get the reference to the asset's collection

  const assetRef = isImage
    ? __getImagesRef(entityId, isOrgUser)
    : __getVideosRef(entityId, isOrgUser);
  // Get the reference to the asset's document
  const assetDocRef = doc(assetRef, assetGlobalId);
  // Get the download URLs for the asset
  const response = await storageRepository.getAssetDownloadUrlsWithDelay(
    entityId,
    assetGlobalId,
    documentTypeIdx,
    fileExtension,
    5000
  );

  if (response.success) {
    const updatedAssetData = {
      ...assetData,
      downloadURL: response.downloadUrl,
      thumbnailURL: response.thumbnailDownloadUrl,
      thumbnailSessionURL: response.sessionThumbnailDownloadUrl,
      modifiedAt: serverTimestamp(),
    };
    // Update the asset data in Firestore
    updateDoc(assetDocRef, updatedAssetData, { merge: true });
    return updatedAssetData;
  } else {
    return null; // Return null to skip rendering the asset
  }
}

export default {
  querySessionsDataByQueryIdx,
  getImagesBySession,
  getVideosBySession,
  getPatientBySession,
  getAllTags,
  getUserReference,
  getUserDocument,
  getUserData,
  getUserClaims,
  setConnectAccessed,
  getAllPatients,
  createUser,
  createSession,
  createImage,
  createVideo,
  createPatient,
  updateUser,
  updateSession,
  updateImage,
  updateVideo,
  updatePatient,
  deleteUser,
  deleteSession,
  deleteImage,
  deleteVideo,
  updateLastDeviceSync,
  listenDeviceSyncChanges,
  getNewGlobalId,
  refetchDownloadUrls,
  getSessionById,
  countImagesInStorage,
  countVideosInStorage,
  getOrgData,
  querySessionsByOwner,
};
